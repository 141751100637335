import {APP_INITIALIZER, NgModule}                                         from '@angular/core';
import {BrowserModule, Title}                                              from '@angular/platform-browser';
import {RouteReuseStrategy}                                                from '@angular/router';
import {ServiceWorkerModule}                                               from '@angular/service-worker';
import {environment}                                                       from '../environments/environment';
import {AppRoutingModule}                                                  from './app-routing.module';
import {AppComponent}                                                      from './app.component';
import {SherpaService}                                                     from './services/sherpa.service';
import {IonicToastErrorMessage}                                            from './classes/core/ionicToastErrorMessage';
import {DataService}                                                       from './services/data.service';
import {ActiveRouteService}                                                from './services/active-route.service';
import {IonicModule, IonRouterOutlet, IonicRouteStrategy, ToastController} from '@ionic/angular';
import {IonicStorageModule}                                                from '@ionic/storage-angular';
import {Drivers}                          from '@ionic/storage';
import {Geolocation}                      from '@ionic-native/geolocation/ngx';
import {StreamingMedia}                   from '@ionic-native/streaming-media/ngx';
import {BackgroundGeolocation}            from '@ionic-native/background-geolocation/ngx';
import {LaunchNavigator}                  from '@ionic-native/launch-navigator/ngx';
import {Network}                          from '@ionic-native/network/ngx';
import {ScreenOrientation}                from '@ionic-native/screen-orientation/ngx';
import {StatusBar}                        from '@awesome-cordova-plugins/status-bar/ngx';
import {HttpClientModule, HttpClient}     from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader}              from '@ngx-translate/http-loader';
import {TranslateConfigService}           from './services/translate-config.service';

export function createTranslateLoader(http: HttpClient) {
	// @ts-ignore
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export let sherpa: SherpaService;

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		IonicStorageModule.forRoot({
			name: '__rmdb',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		// @ts-ignore
		TranslateModule.forRoot({
			defaultLanguage: 'nl',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		})
	],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		}, {
			provide: APP_INITIALIZER,
			useFactory: (sherpaService: SherpaService, toast: ToastController) => () => {
				sherpa = sherpaService;
				return sherpa.buildSherpas(new IonicToastErrorMessage(toast));
			},
			deps: [SherpaService, ToastController],
			multi: true
		}, {
			provide: APP_INITIALIZER,
			useFactory: (data: DataService, activeRoute: ActiveRouteService) => () => {
				return data.createStorages().then(mainStorage => {
					activeRoute.init();
				});
			},
			deps: [DataService, ActiveRouteService],
			multi: true
		},
		Geolocation,
		StreamingMedia,
		BackgroundGeolocation,
		LaunchNavigator,
		IonRouterOutlet,
		Network,
		ScreenOrientation,
		StatusBar,
		TranslateConfigService
	],
	bootstrap: [AppComponent]
})
export class AppModule {

	constructor(private screenOrientation: ScreenOrientation) {


	}

}
