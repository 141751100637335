import {Injectable}       from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataService}      from './data.service';


@Injectable({
	providedIn: 'root'
})
export class TranslateConfigService {
	constructor(
		private translate: TranslateService, private data: DataService
	) { }

	getDefaultLanguage(){
		let language = 'nl';
		this.translate.setDefaultLang(language);
		this.data.getLanguage().then(lang => {
			if(lang != null) {
				language = lang;
				this.translate.setDefaultLang(lang);
			}
		});
		return language;
	}

	setLanguage(setLang) {
		// this.translate.use(setLang);
		this.translate.setDefaultLang(setLang);
		this.data.setLanguage(setLang);
	}
}
