import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import _ from 'lodash';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import LineString from 'ol/geom/LineString';
import {Coordinate} from 'ol/coordinate';
import {fromLonLat} from 'ol/proj';
import {getLength} from 'ol/sphere';
import {UtilService} from './util.service';
import {DataService} from './data.service';
import {NotificationService} from './notification.service';
import {ActiveRoute, ActiveRouteMode, ActiveRouteState} from '../classes/core/ActiveRoute';
import {Poi} from '../classes/routes/Poi';
import {Route} from '../classes/routes/Route';
import {Platform} from '@ionic/angular';
import {GeolocationOptions, Geoposition} from '@ionic-native/geolocation';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {
	BackgroundGeolocationPlugin,
	CallbackError,
	Location,
	WatcherOptions
} from '@capacitor-community/background-geolocation';
import {Capacitor, registerPlugin} from '@capacitor/core';
import {environment} from '../../environments/environment';
import NoSleep from 'nosleep.js';

export const ACTIVE_ROUTE_KEY = 'active-route';
const DEFAULT_POI_RADIUS = 25;
const backgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>('BackgroundGeolocation');

const {KalmanFilter} = require('kalman-filter');

@Injectable({
	providedIn: 'root'
})
export class ActiveRouteService implements OnDestroy {

	positionSubscription: Subscription;
	backgroundPositionSubscription: Subscription;

	positionOptions: GeolocationOptions = {
		timeout: 3000,
		enableHighAccuracy: true,
		maximumAge: 300000
	};

	BackgroundGeolocationWatcherId: string = null;
	backgroundLocationOptions = {
		backgroundMessage: 'Cancel to prevent battery drain.',
		backgroundTitle: 'Tracking You.',
		requestPermissions: true,
		stale: false,
		distanceFilter: 6
	} as WatcherOptions;

	// kalmanFilter: KalmanFilter;
	kalmanFilter: any;
	observations: any[] = [];

	activeRouteSubscription: Subscription;
	activeRoute: ActiveRoute = null;
	activeRouteSubject: BehaviorSubject<ActiveRoute>; // emits the current value to new subscribers


	// currentLocationSubject: BehaviorSubject<Coordinate>; // emits the current value to new subscribers
	currentLocationSubject: BehaviorSubject<any>; // emits the current value to new subscribers

	noSleep: NoSleep = null;

	firstLocationRequest: boolean = true;

	// tslint:disable-next-line:max-line-length
	constructor(private router: Router, private data: DataService, private geo: Geolocation, private util: UtilService, private notificationService: NotificationService, private platform: Platform) {
		this.activeRouteSubject = new BehaviorSubject<ActiveRoute>(null);
		this.currentLocationSubject = new BehaviorSubject<Coordinate>(null);

		this.activeRouteSubscription = this.activeRouteSubject.subscribe((r: ActiveRoute) => {
			this.activeRoute = r;
		});

		if(!Capacitor.isNativePlatform()) {
			this.noSleep = new NoSleep();
		}
	}

	init() {
		this.platform.ready().then(platformSource => {
			console.log('[INITIALIZER] Capacitor', {
				platform: Capacitor.getPlatform(),
				native: Capacitor.isNativePlatform(),
				source: platformSource,
			});

			this.data.get(ACTIVE_ROUTE_KEY)
				.then((activeRoute: ActiveRoute) => {
					console.log("active route key");
					this.activeRouteSubject.next(activeRoute);
					this.startRoute(activeRoute.routeObj, activeRoute.mode, activeRoute.teamName, false);

				})
				.catch(() => {
					this.activeRouteSubject.next(null);
				});
		});

		this.kalmanFilter = new KalmanFilter({
			observation: {
				name: 'sensor',
				sensorDimension: 2
			}
		});
	}

	async startRoute(route: Route, mode: ActiveRouteMode, teamName: string = null, navigate = true) {

		console.log('startRoute()');

		this.keepBrowserAlive();

		this.storeActiveRoute(route, mode, teamName)
			.then(activeRoute => {
				this.activeRouteSubject.next(activeRoute);

				if (Capacitor.isNativePlatform()) {
					this.watchBackgroundGeoLocation(activeRoute, true);
				} else {
					// this.watchPosition(activeRoute, true);
					this.watchPosition(activeRoute, false);
				}

				if (navigate) {
					this.router.navigate(['/app/routes', route.id, 'detail', 'map']);
				}
			});
	}

	stopRoute() {
		this.activeRoute = null;

		if(this.noSleep !== null && this.noSleep.isEnabled) {
			this.noSleep.disable();
		}

		if (this.positionSubscription) {
			this.positionSubscription.unsubscribe();
		}

		if (this.backgroundPositionSubscription) {
			this.backgroundPositionSubscription.unsubscribe();
		}

		if (backgroundGeolocation && this.BackgroundGeolocationWatcherId) {
			backgroundGeolocation.removeWatcher({
				id: this.BackgroundGeolocationWatcherId
			}).then(() => {
				this.BackgroundGeolocationWatcherId = null;
				console.log('watcher removed');
			});
		}

		this.data.db.remove(ACTIVE_ROUTE_KEY).then(() => {
			this.activeRouteSubject.next(null);
		});
	}

	storeActiveRoute(route: Route, mode: ActiveRouteMode, teamName: string): Promise<ActiveRoute> {
		let activeRoute;

		if (this.activeRoute?.routeId === route.id) {
			activeRoute = this.activeRoute;
			activeRoute.mode = mode;
			activeRoute.state = ActiveRouteState.RUNNING;

		} else {
			activeRoute = new ActiveRoute(route.id, route.pois, mode);
			activeRoute.name = route.name;
			activeRoute.startTime = new Date();
			activeRoute.teamName = teamName;
			activeRoute.tracking = [];
			activeRoute.state = ActiveRouteState.RUNNING;
			activeRoute.routeObj = route;
			activeRoute.score = 0;
		}

		return this.data.set(ACTIVE_ROUTE_KEY, activeRoute);
	}

	update(activeRoute: ActiveRoute): Promise<void> {
		if (this.activeRoute == null) {
			return Promise.reject('No active route to update');
		}

		if (this.activeRoute.routeId !== activeRoute.routeId) {
			return Promise.reject('Active route -> routeId does not match input routeId');
		}

		return this.data.set(ACTIVE_ROUTE_KEY, activeRoute).then(r => {
			this.activeRouteSubject.next(r);
			return;
		});
	}

	watchPosition(activeRoute: ActiveRoute, tracking: boolean) {

		console.log('watchPosition');

		this.positionSubscription = this.geo.watchPosition(this.positionOptions)
			.pipe(filter(p => 'coords' in p))
			.subscribe(async (position: Geoposition) => {

				let lonLat;

				this.observations.push([position.coords.longitude, position.coords.latitude]);
				let result = this.kalmanFilter.filterAll(this.observations);

				// if (position.coords.speed <= 0.5) {
				// 	return;
				// }

				// this.kalmanFilter.process(position.coords.latitude, position.coords.longitude, 1, position.timestamp);

				if (!!result) {
					let coords = _.last(result);
					lonLat = coords as Coordinate;
					// lonLat = [this.kalmanFilter.lon, this.kalmanFilter.lat] as Coordinate;

					const coordinate = fromLonLat(lonLat);

					this.handleLocation(coordinate, activeRoute, tracking);
					this.currentLocationSubject.next({
						coordinate: coordinate,
						speed: position.coords.speed
					});
				}
			});

		// todo error when no position
	}

	watchBackgroundGeoLocation(activeRoute: ActiveRoute, tracking: boolean) {
		console.log('watchBackgroundGeoLocation');

		const callback = (location: Location | undefined, error: CallbackError | undefined) => {
			if (error) {
				if (error.code === 'NOT_AUTHORIZED') {
					backgroundGeolocation.openSettings();
				}

				return console.error('[ERROR] handleLocation', error);
			}

			if (location) {

				let lonLat;

				this.observations.push([location.longitude, location.latitude]);
				let result = this.kalmanFilter.filterAll(this.observations);

				// TODO: 07/03/2023 find better solution for first coordinate
				if (location.speed === null && !this.firstLocationRequest) {
					return;
				}

				// this.kalmanFilter.process(location.latitude, location.longitude, location.accuracy, location.time);

				if (!!result) {
					this.firstLocationRequest = false;
					let coords = _.last(result);
					lonLat = coords as Coordinate;
					// lonLat = [this.kalmanFilter.lon, this.kalmanFilter.lat] as Coordinate;

					const coordinate = fromLonLat(lonLat);

					this.handleLocation(coordinate, activeRoute, tracking);
					this.currentLocationSubject.next({
						coordinate: coordinate,
						speed: location.speed
					});
				}
			}
		};

		const options = _.clone(this.backgroundLocationOptions);
		options.backgroundMessage = 'Tik om naar de route te gaan.';

		if(Capacitor.getPlatform() === 'android' && environment.configPublic.app_android_name) {
			options.backgroundTitle = environment.configPublic.app_android_name + ' houdt uw locatie bij';
		}
		else if(Capacitor.getPlatform() === 'ios' && environment.configPublic.app_ios_name) {
			options.backgroundTitle = environment.configPublic.app_ios_name + ' houdt uw locatie bij';
		}
		else {
			options.backgroundTitle = 'Routemaker houdt uw locatie bij';
		}

		if (!!this.BackgroundGeolocationWatcherId) {
			backgroundGeolocation.removeWatcher({
				id: this.BackgroundGeolocationWatcherId
			}).then(() => {
				this.BackgroundGeolocationWatcherId = null;
				console.log('watcher removed');

				backgroundGeolocation.addWatcher(options, callback).then((watcherId) => {
					this.firstLocationRequest = true;
					this.BackgroundGeolocationWatcherId = watcherId;
					console.log('addWatcher', watcherId);
				});
			});
		} else {
			backgroundGeolocation.addWatcher(options, callback).then((watcherId) => {
				this.firstLocationRequest = true;
				this.BackgroundGeolocationWatcherId = watcherId;
				console.log('addWatcher', watcherId);
			});
		}
	}

	handleLocation(coordinate: Coordinate, activeRoute: ActiveRoute, tracking: boolean) {

		console.log('handleLocation()');

		if (tracking) {
			// If tracking line is too long clear it.
			if(activeRoute.tracking.length > 0) {
				const lastTrackingCoord = activeRoute.tracking[activeRoute.tracking.length - 1];
				const trackingLength = new LineString([coordinate, lastTrackingCoord]);
				if (getLength(trackingLength) > 200) {
					// Clear when longer than 200 meter
					activeRoute.tracking = [];
				}
			}
			activeRoute.tracking.push(coordinate);
		}

		_.forEach(activeRoute.pois, poi => {
			const lineToPoi = new LineString([coordinate, poi.geom]);

			poi._distanceInMeters = getLength(lineToPoi);
			poi._distanceFormatted = this.util.formatLength(lineToPoi);

			if (!poi._notificationFlag) {
				const radius = poi.notification_radius || DEFAULT_POI_RADIUS;
				poi._notificationFlag = this.checkNearby(poi, activeRoute.pois, activeRoute.routeObj, radius);

				if(poi._notificationFlag && poi.kind === 'speurpunt') {
					activeRoute.score += poi.points;
				}
			}
		});

		// update active route
		this.update(activeRoute);
	}

	checkNearby(poi: Poi, pois: Poi[], route: Route, radius = DEFAULT_POI_RADIUS) {
		if (poi._distanceInMeters > radius) {
			return false;
		}

		if(this.skipShowEndPoi(poi, pois)){
			return false;
		}

		this.notificationService.notify(poi, pois, route);

		return true;
	}

	skipShowEndPoi(poi: Poi, pois: Poi[]){
		const maxPois = pois.length;
		let flagCount = 0;
		if(poi.kind === 'eindpunt'){
			_.forEach(pois, p => {
				if (p._notificationFlag) {
					flagCount++;
				}
			});
			if(flagCount <= (maxPois / 2)){
				return true;
			}
		}

		return false;
	}

	isNotified(poiId: number) {
		if(this.activeRoute == null) {
			return false;
		}

		return _.some(this.activeRoute.pois, {id: poiId, _notificationFlag: true});
	}

	syncNotificationFlagFromActiveRoute(pois: Poi[], matchPoiKind: string = null) {
		_.forEach(pois, poi => {
			if (matchPoiKind == null || poi.kind === matchPoiKind) {
				poi._notificationFlag = this.isNotified(poi.id);
			}
		});
	}

	// (must be wrapped in a user input event handler e.g. a mouse or touch handler)
	keepBrowserAlive() {
		const listener = (e) => {
			document.removeEventListener('click', listener);
			this.noSleep.enable();
		};

		document.addEventListener('click', listener);
	}

	ngOnDestroy() {
		if (this.activeRouteSubscription) {
			this.activeRouteSubscription.unsubscribe();
		}
	}
}
