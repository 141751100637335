import {Poi}        from '../routes/Poi';
import {Coordinate} from 'ol/coordinate';
import {Route}      from '../routes/Route';

export class ActiveRoute {
	name: string;
	tracking: Coordinate[];
	teamName: string;
	score: number;
	startTime: Date;
	routeObj: Route;

	constructor(public routeId: number, public pois: Poi[], public mode: ActiveRouteMode) {
	}
}

export enum ActiveRouteState {
	RUNNING = 'running',
	STOPPED = 'stopped',
	VOID = 'void'
}

export enum ActiveRouteMode {
	ONLINE = 'online',
	OFFLINE = 'offline'
}

