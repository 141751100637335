import {Component, NgZone, OnDestroy, OnInit}                        from '@angular/core';
import {AlertController, IonRouterOutlet, Platform, ToastController} from '@ionic/angular';
import {Location}                                                    from '@angular/common';
import {environment}                                                 from '../environments/environment';
import {Capacitor}                                                   from '@capacitor/core';
import _                                                             from 'lodash';
import {HttpClient}                                                  from '@angular/common/http';
import {DataService}                                                 from './services/data.service';
import {Router}                                                      from '@angular/router';
import {App, URLOpenListenerEvent}                                   from '@capacitor/app';
import {Network}                                                     from '@capacitor/network';
import {NetworkStatus, NetworkService}                               from './services/network.service';
import {TranslateConfigService}                                      from './services/translate-config.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

	appName;
	backButtonSubscription;
	manifestSubscription;

	connectSubscription;
	disconnectSubscription;

	selectedLanguage: string;

	latestNetworkStatus: NetworkStatus = null;

	// tslint:disable-next-line:max-line-length
	constructor(private platform: Platform, private router: Router, private data: DataService, private alertController: AlertController,
				private httpClient: HttpClient, private toast: ToastController, private location: Location,
				private routerOutlet: IonRouterOutlet, private zone: NgZone, private networkService: NetworkService,
				private translateConfigService: TranslateConfigService) {
	}

	ngOnInit() {
		this.initializeApp();

		if ('serviceWorker' in navigator) {
			this.initializeServiceWorkerRegistrations();
		}

		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const userCreatedRoute = event.url.split('/u/').pop();
				if (userCreatedRoute) {
					// UserCreatedRoute
					this.data.addUserCreatedRouteToRoutes(Number(userCreatedRoute));
				}
				// If no match, do nothing - let regular routing
				// logic take over
			});
		});


		this.selectedLanguage = this.translateConfigService.getDefaultLanguage();

	}

	initializeServiceWorkerRegistrations() {

		console.log('initializeServiceWorkerRegistrations');

		// reload once, when the new Service Worker starts activating
		let refreshing;
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (refreshing) {
				return;
			}
			refreshing = true;
			window.location.reload(true);
		});

		// navigator.serviceWorker.getRegistration().then(reg => {
		// 	console.log('reg', reg);
		//
		// 	this.listenForWaitingServiceWorker(reg, this.showRefreshToast);
		//
		// 	reg.update().then(r => {
		// 		console.log('reg updated');
		// 	});
		// });


	}

	async showRefreshToast(registration: ServiceWorkerRegistration) {

		if (!registration.waiting) {
			// Just to ensure registration.waiting is available before calling postMessage()
			return;
		}

		const toast = await this.toast.create({
			message: 'Er is een update beschikbaar! Klik op update om de laatste wijzigingen te zien.',
			position: 'bottom',
			buttons: [{
				text: 'Update',
				role: 'submit',
				handler: () => {
					registration.waiting.postMessage('skipWaiting');
					// await this.data.db.set('version', version);
				}
			}],
		});
		await toast.present();
	}

	listenForWaitingServiceWorker(registration: ServiceWorkerRegistration, callback) {

		const awaitStateChange = () => {
			console.log('>> awaitStateChange');

			registration.installing.addEventListener('statechange', event => {
				// @ts-ignore
				if (event.target.state === 'installed') {
					callback(registration); // A new service worker is available, inform the user
				}
			});
		};

		if(!registration) {
			return;
		}

		if(registration.waiting) {
			return callback(registration);
		}

		if(registration.installing) {
			awaitStateChange();
			return;
		}

		registration.addEventListener('updatefound', awaitStateChange);
	}

	async initializeApp() {

		this.platform.ready().then(async () => {
			if (Capacitor.isNativePlatform()) {
				if (Capacitor.getPlatform() === 'android') {
					this.appName = environment.configPublic?.app_android_name;
				}

				if (Capacitor.getPlatform() === 'ios') {
					this.appName = environment.configPublic?.app_ios_name;
				}

				Network.addListener('networkStatusChange', status => {
					const ns = status.connected ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;

					if(this.latestNetworkStatus == null) {
						this.networkService.updateNetworkStatus(ns, status.connected);
					}
					else {
						if(this.latestNetworkStatus !== ns) {
							this.networkService.updateNetworkStatus(ns);
						}
					}

					this.latestNetworkStatus = ns;
				});

				const s = await Network.getStatus();
				const connectionStatus = s.connected ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
				// show toast when user starts offline

				await this.networkService.updateNetworkStatus(connectionStatus, connectionStatus === NetworkStatus.ONLINE);
			}

			// this.setBackButtonEvent();
		});

		if(Capacitor.isNativePlatform()) {
			return;
		}

		const currentVersion = await this.data.db.get('version');

		this.manifestSubscription = this.httpClient.get<any>('manifest.webmanifest').subscribe(async o => {

			// first time
			if(_.isEmpty(currentVersion)) {
				this.data.db.set('version', o.version);
				return;
			}

			const updateAvailable = !_.isEqual(o.version, currentVersion);

			if (updateAvailable) {
				const toast = await this.toast.create({
					message: 'Er is een update beschikbaar! Klik op update om de laatste wijzigingen te zien.',
					position: 'bottom',
					buttons: [{
						text: 'Update',
						role: 'submit',
						handler: () => {
							this.forceUpdate(o.version);
						}
					}],
				});
				await toast.present();
			}
		});
	}

	async forceUpdate(version: string) {
		// console.log('forceUpdate', version);
		// console.log('forceUpdate', navigator);

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations()
				.then(async registrations => {
					await Promise.all(registrations.map(r => {
						r.update();
					}));

					return this.data.db.set('version', version);
				})
				.then(() => {
					window.location.reload(true);
				});
		} else {
			await this.data.db.set('version', version);
			window.location.reload(true);
		}
	}

	setBackButtonEvent() {
		console.log('set backbutton');

		this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(-1, processNextHandler => {
			console.log('canGoBack', this.routerOutlet.canGoBack());

			//
			// if (this.location.isCurrentPathEqualTo('/routes')) {
			// 	this.presentCloseAppAlert();
			// } else {
			// 	console.log('back');
			// 	this.location.back();
			// }
		});
	}

	async presentCloseAppAlert() {
		const alert = await this.alertController.create({
			message: `Wil je ${this.appName || 'Routemaker'} afsluiten?`,
			buttons: [{
				text: 'Annuleer',
				role: 'Cancel'
			}, {
				text: 'Ja, sluit af',
				handler: () => {
					navigator['app'].exitApp();
				}
			}]
		});

		await alert.present();
	}

	ngOnDestroy() {
		if(this.backButtonSubscription) {
			this.backButtonSubscription.unsubscribe();
		}
		if(this.manifestSubscription) {
			this.manifestSubscription.unsubscribe();
		}
	}

}
