
export const environment = {
	production: true,
	splashscreen: '',
	routemakerJsonUrl: 'https://barcelonatips.api.routemaker.nl/routemaker/v2/sherpa.json',
	routemakerContentUrl: 'https://barcelonatips.api.routemaker.nl/',
	routemakerViewerUrl: 'https://barcelonatips.viewer.routemaker.nl/viewer/route/nl/',
	routingJsonUrl: 'https://routing.irias.nl/iriasrouting/sherpa.json',
	routeplannerUrl: null,
	customerCode: 'barcelonatips',
	configPublic: {} as any,
	apiKey: '59080B2D-9271-4B6E-BD4E-59CA0D275F82',
	icons: [],
	filterRoutes: [],
	fetchAllCustomers: false,
	showMainCustomerFilters: true,
	reviewsEnabled: true,
	paymentsEnabled: true,
	supportEmail: 'info@barcelonatips.nl',
	appName: 'BCN Self-guided',
	organisationName: 'Barcelonatips',
	pwaOnly: false as boolean
};
