import {Injectable}                  from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Capacitor}                   from '@capacitor/core';
import {Platform, ToastController}   from '@ionic/angular';
import {Storage}                     from '@ionic/storage-angular';

export enum NetworkStatus {
	ONLINE = 'online',
	OFFLINE = 'offline'
}

@Injectable({
	providedIn: 'root'
})
export class NetworkService {

	private status: BehaviorSubject<NetworkStatus> = new BehaviorSubject(NetworkStatus.OFFLINE);


	constructor(private toastController: ToastController, private storage: Storage, private platform: Platform) {
		this.platform.ready().then(() => {
			this.initNetworkEvents();
		});
	}

	initNetworkEvents() {

		if (!Capacitor.isNativePlatform()) {

			if(window.navigator.onLine) {
				this.updateNetworkStatus(NetworkStatus.ONLINE, true);
			}
			else {
				this.updateNetworkStatus(NetworkStatus.OFFLINE, false);
			}

			window.addEventListener('offline', () => {
				if (this.status.getValue() === NetworkStatus.ONLINE) {
					this.updateNetworkStatus(NetworkStatus.OFFLINE);
				}
			});

			window.addEventListener('online', () => {
				if (this.status.getValue() === NetworkStatus.OFFLINE) {
					this.updateNetworkStatus(NetworkStatus.ONLINE);
				}
			});

			return;
		}



	}

	async updateNetworkStatus(status: NetworkStatus, noToast = false) {
		this.status.next(status);

		if(noToast) {
			return;
		}

		const connection = status === NetworkStatus.OFFLINE ? 'offline' : 'online';
		const toast = await this.toastController.create({
			message: `U bent ${connection}`,
			duration: 1500,
			position: 'top'
		});

		await toast.present();
	}

	onNetworkChange(): Observable<NetworkStatus> {
		return this.status;
	}

	getCurrentNetworkStatus(): NetworkStatus {
		return this.status.getValue();
	}

	storeSherpaRequest(key: string, functionName: string, params: any) {
	}

	sendSherpaRequest() {
	}

}
