import {ErrorMessageStrategy} from '../../services/sherpa.service';
import {ToastController}      from '@ionic/angular';
import {SherpaError}          from '../sherpa/SherpaError';

export class IonicToastErrorMessage implements ErrorMessageStrategy {

	constructor(private toastController: ToastController) {
	}

	async show(sherpaError: SherpaError) {
		const toast = await this.toastController.create({
			message: sherpaError.message,
			color: 'danger',
			position: 'middle',
			duration: 1000,
			buttons: [
				{
					text: 'Sluit',
					role: 'cancel',
					handler: () => {
					}
				}
			]
		});
		await toast.present();
	}

}
