import {Injectable}                from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {environment}               from '../../environments/environment';
import LineString                  from 'ol/geom/LineString';
import {getLength}                 from 'ol/sphere';
import {Coordinate}                from 'ol/coordinate';
import {modulo}                    from 'ol/math';
import _                           from 'lodash';
import JSZip                       from 'jszip';
import {Image as RouteImage}       from '../classes/routes/Image';
import {Route}                     from '../classes/routes/Route';
import {Capacitor}                 from '@capacitor/core';
import {StatusBar, Style}          from '@capacitor/status-bar';
import {BehaviorSubject, Observable} from "rxjs";
import {NetworkStatus} from "./network.service";

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	public appInstallChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public deferredPrompt;

	constructor(private http: HttpClient) {
	}

	async updateStatusBar(brandColor = false) {
		if (!Capacitor.isNativePlatform()) {
			return;
		}

		const bg = brandColor && !_.isEmpty(environment.configPublic.brand_color);

		if(Capacitor.getPlatform() === 'android') {
			await StatusBar.setBackgroundColor({
				color: bg ? environment.configPublic.brand_color : '#f5f5f5'
			});

			await StatusBar.setStyle({
				style: brandColor ? Style.Light : Style.Default
			});
		}
		else if(Capacitor.getPlatform() === 'ios') {
			await StatusBar.setStyle({
				style: brandColor ? Style.Dark : Style.Light
			});
		}

	}

	isStandalone() {
		// @ts-ignore
		return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
	}

	gatherCoordinates(routeLines: any[]): Coordinate[] {
		const coordinates: Coordinate[] = [];

		_.forEach(routeLines, (lineObj) => {
			if (lineObj.geom != null) {
				coordinates.push.apply(coordinates, lineObj.geom);
			}
		});

		return coordinates;
	}

	formatLength(line: LineString): string {
		const meters = getLength(line);
		return this.formatLengthByMetersAdvanced(meters);
	}

	formatLengthByMeters(meters: number) {
		let output;
		if (meters > 100) {
			output = Math.round((meters / 1000) * 100) / 100 + ' ' + 'km';
		} else {
			output = Math.round(meters * 100) / 100 + ' ' + 'm';
		}

		return output;
	}

	formatLengthByMetersAdvanced(meters: number) {

		if (meters <= 50) {
			return `${(Math.round(meters))} meter`;
		} else if (meters <= 100) {
			return `${(Math.round(meters / 10) * 10)} meter`;
		} else if (meters <= 500) {
			return `${(Math.round(meters / 50) * 50)} meter`;
		} else if (meters < 1000) {
			return `${(Math.round(meters / 100) * 100)} meter`;
		}

		return `${(meters / 1000).toFixed(1).replace('.', ',')} km`;
	}

	getImageUrlCutout(image: RouteImage, width: number, height: number) {
		if (_.isNil(image)) {
			return '';
		}

		_.forEach(image.cut, (c, key) => {
			image.cut[key] = _.round(c, 0);
		});

		const cut = !!image.cut ? '-r' + image.cut.join('x') : '';
		const ext = image.content_type === 'image/png' ? 'png' : 'jpg';

		return '/content/img/' + image.id + '/img' + cut + '-C' + width + 'x' + height + '.' + ext;
	}

	getImageUrl(image: RouteImage, size = 400, baseUrl = environment.routemakerContentUrl) {
		if (_.isNil(image)) {
			return '';
		}

		_.forEach(image.cut, (c, key) => {
			image.cut[key] = _.round(c, 0);
		});

		baseUrl = _.trimEnd(baseUrl, '/');

		const cut = !!image.cut ? '-r' + image.cut.join('x') : '';
		const ext = image.content_type === 'image/png' ? 'png' : 'jpg';

		return baseUrl + '/content/img/' + image.id + '/img' + cut + '-t' + size + '.' + ext;
	}

	getImageUrlPartner(image: RouteImage) {
		return this.getImageUrl(image, 100);
	}

	async downloadRouteZip(url: string, totalSize, expectedImageIds: string[]) {

		const tiles = new Map<string, string>();
		const images = new Map<string, string>();

		return this.http.get(url, {
			responseType: 'blob',
			reportProgress: true,
			observe: 'events'
		})
			.toPromise()
			.then(async event => {

				if (event.type === HttpEventType.DownloadProgress) {
					const progress = Math.round((100 * event.loaded) / totalSize);
					console.log(progress);

				} else if (event.type === HttpEventType.Response) {
					console.log('donwnload done');

					return new JSZip()
						.loadAsync(event.body)
						.then((zip: JSZip) => {
							const promises = [];

							zip.forEach((path, obj) => {
								const splitPath = _.split(path, '.');

								if (_.includes(expectedImageIds, splitPath[0])) {
									const p1 = obj.async('base64').then(data64 => {
										const dataURI = 'data:image/jpeg;base64,' + data64;
										images.set(splitPath[0], dataURI);
									});
									promises.push(p1);
									return;
								}

								const p2 = obj.async('base64').then(data64 => {
									const dataURI = 'data:image/jpeg;base64,' + data64;
									let key = _.trimEnd(path, '.png');
									key = _.trimEnd(key, '.jpg');
									tiles.set(`TILE_${key}`, dataURI);
								});

								promises.push(p2);
							});

							return Promise.all(promises).then(x => {
								return {
									tiles,
									images
								};
							});
						});


				}
			});
	}

	convertBlobToBase64(blob: Blob): Promise<any> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.readAsDataURL(blob);
		});
	}

	async getBase64ImageFromUrl(imageUrl: string, fallbackImage = 'assets/images/image-not-found.png') {
		const response = await fetch(imageUrl, {
			mode: 'cors'
		});

		if (!_.isEqual(response.status, 200)) {
			return Promise.resolve(fallbackImage);
		}

		const blob = await response.blob();
		return this.convertBlobToBase64(blob);
	}

	formatBytes(bytes: number, decimals = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	// convert radians to degrees
	radToDeg(rad: number) {
		return (rad * 360) / (Math.PI * 2);
	}

	// convert degrees to radians
	degToRad(deg: number) {
		return (deg * Math.PI * 2) / 360;
	}

	// modulo for negative values
	mod(n: number) {
		return ((n % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
	}


	/*
	* Use the tracing example for getting as part of linestring
	* https://openlayers.org/en/latest/examples/tracing.html
	* */

	// coordinates; will return the length of the [a, b] segment
	length(a, b) {
		return Math.sqrt(
			(b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1])
		);
	}

	// coordinates; will return true if c is on the [a, b] segment
	isOnSegment(c, a, b) {
		const lengthAc = this.length(a, c);
		const lengthAb = this.length(a, b);
		const dot = ((c[0] - a[0]) * (b[0] - a[0]) + (c[1] - a[1]) * (b[1] - a[1])) / lengthAb;
		return Math.abs(lengthAc - dot) < 1e-6 && lengthAc < lengthAb;
	}

	// returns a coordinates array which contains the segments of the feature's
	getPartialCoords(linestring: LineString, startPoint: Coordinate, endPoint: Coordinate) {
		const coordinates = linestring.getCoordinates();

		// tslint:disable-next-line:one-variable-per-declaration
		let i,
			pointA,
			pointB,
			startSegmentIndex = -1;

		for (i = 0; i < coordinates.length; i++) {
			pointA = coordinates[i];
			pointB = coordinates[modulo(i + 1, coordinates.length)];

			// check if this is the start segment dot product
			if (this.isOnSegment(startPoint, pointA, pointB)) {
				startSegmentIndex = i;
				break;
			}
		}

		const cwCoordinates = [];
		let cwLength = 0;

		// build clockwise coordinates
		for (i = 0; i < coordinates.length; i++) {

			pointA = coordinates[modulo(i + startSegmentIndex, coordinates.length)];
			pointB = coordinates[modulo(i + startSegmentIndex + 1, coordinates.length)];

			if (i === 0) {
				pointA = startPoint;
			}

			cwCoordinates.push(pointA);

			if (this.isOnSegment(endPoint, pointA, pointB)) {
				cwCoordinates.push(endPoint);
				cwLength += this.length(pointA, endPoint);
				break;
			} else {
				cwLength += this.length(pointA, pointB);
			}
		}

		return cwCoordinates;
	}

	public getRouteKind(route: Route, customerFields) {
		const customerField = _.find(customerFields, {label: 'Soort'});
		if (!customerField) {
			return null;
		}

		const routeField = route.fields_single[customerField.id];
		// console.log('found', routeField);

		if (!routeField) {
			return null;
		}

		return routeField.label;
	}

	public routeFieldValueIsSet(route, routefields, fieldName, valueName) {
		const rf = _.find(routefields, {label: fieldName});
		if (!rf) {
			return false;
		}
		const rfv = _.find(rf.values, {label: valueName});
		if (!rfv) {
			return false;
		}
		if (rf.kind === 'any') {
			const l = route.fields_any[rf.id] || [];
			for (let i = 0; i < l.length; i++) {
				if (l[i].id === rfv.id) {
					return true;
				}
			}
		} else if (rf.kind === 'single') {
			const e = route.fields_single[rf.id] || {};
			return e.id === rfv.id;
		} else {
			// should not happen
		}
		return false;
	}

	public isSpeurTocht(route, routefields) {
		const foundInOverig = this.routeFieldValueIsSet(route, routefields, 'Overig', 'Speurtocht');
		const foundInType = this.routeFieldValueIsSet(route, routefields, 'Type', 'Speurtocht');

		return foundInOverig || foundInType;
	}

	public isCorrectRouteFilter(routeKind) {
		if (environment.filterRoutes != null && environment.filterRoutes.length > 0) {
			return _.includes(environment.filterRoutes, routeKind);
		} else {
			return true;
		}
	}

	public doRouteFiltering(routes, routeFields) {
		// tslint:disable-next-line:no-shadowed-variable
		let routeField = _.find(routeFields, (routeField) => {
			return routeField.label.toLowerCase() === 'soort';
		});

		return _.filter(routes, (route: Route) => {

			let routeKind: string;
			if (!!routeField) {
				_.forEach(routeField.values, (fieldValue) => {
					let found = _.find(route['fields_single'], (field) => {
						return fieldValue.id === field.id;
					});

					if (!!found) {
						routeKind = found.label;
					}
				});

				if (!!routeKind) {
					routeKind = routeKind.toLowerCase();
				}
			}
			return this.isCorrectRouteFilter(routeKind);
		});
	}

	public getRouteKindIcon(routeKind: string) {

		if (routeKind == null) {
			return 'fa-route';
		}

		switch (routeKind.toLowerCase()) {
			case 'wandelen':
			case 'wandelroute':
				return 'fa-walking';
			case 'fietsen':
			case 'fietsroute':
				return 'fa-bicycle';
			case 'paardrijden':
			case 'ruiterroute':
				return 'fa-horse';
			case 'trailrunnen':
			case 'trailrunroute':
				return 'fa-running';
			case 'mountainbiken':
				return 'fa-biking-mountain';
			case 'wielrennen':
				return 'fa-biking';
			case 'varen':
				return 'fa-water';
			case 'autoroutes':
				return 'fa-car';
			default:
				return 'fa-route';
		}

	}

	onAppInstallChange(): Observable<boolean> {
		return this.appInstallChange.asObservable();
	}
}
