import {Injectable}                                 from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError}              from 'rxjs';
import {Sherpa, SherpaApi}                   from '../classes/sherpa/Sherpa';
import {SherpaJson}                          from '../classes/sherpa/SherpaJson';
import {catchError, map, retry, shareReplay} from 'rxjs/operators';
import {SherpaResponse}                      from '../classes/sherpa/SherpaResponse';
import {SherpaError}                         from '../classes/sherpa/SherpaError';
import _                                     from 'lodash';
import {environment}                         from '../../environments/environment';
import {sherpa}                              from '../app.module';
import {IonicToastErrorMessage}              from '../classes/core/ionicToastErrorMessage';
import {ToastController}                     from '@ionic/angular';
import {Config}                              from '../classes/core/Config';
import {Icon}                                from '../classes/routes/Icon';
import {DataService}                      from './data.service';
import {NetworkStatus, NetworkService} from './network.service';

@Injectable({
	providedIn: 'root'
})
export class SherpaService {

	customerCode = 'routemaker';

	/* The Sherpa-APIs */
	api: SherpaApi = {};
	routing: SherpaApi = {};

	/* The Sherpa-Jsons */
	apiJson: SherpaJson = {} as SherpaJson;
	routingJson: SherpaJson = {} as SherpaJson;

	readonly httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	};

	readonly httpOptions2 = {
		headers: new HttpHeaders({
			responseType: 'blob',
		})
	};

	constructor(private http: HttpClient, private data: DataService, private networkService: NetworkService) {
	}

	// custom for this app
	async buildSherpas(strategy: ErrorMessageStrategy) {

		if (this.networkService.getCurrentNetworkStatus() === NetworkStatus.OFFLINE) {
			return Promise.resolve();
		}

		return Promise.allSettled([
			this.buildSherpa(environment.routemakerJsonUrl, strategy),
			this.buildSherpa(environment.routingJsonUrl, strategy)
		])
		.then(([routemaker, routing]) => {

			if(routemaker.status === 'fulfilled') {
				// store routemaker api
				this.api = routemaker.value.api;
				this.apiJson = routemaker.value.json;

				sherpa.api.getConfig(environment.customerCode).then((config: Config) => {
					environment.configPublic = config;
				});

				this.data.getIcons().then((icons: Icon[]) => {
					environment.icons = icons;
				});
			}
			else {
				console.log('routemaker-api is not set, reason:', routemaker.reason);
			}

			if(routing.status === 'fulfilled') {
				// store routing api
				this.routing = routing.value.api;
				this.routingJson = routing.value.json;
			}
			else {
				console.log('routing-api is not set, reason:', routing.reason);
			}

		});
	}

	async buildSherpa(url: string, strategy: ErrorMessageStrategy = new ConsoleErrorMessage()): Promise<Sherpa> {
		console.log('[INITIALIZER] Sherpa:', url);
		return await this.setupSherpa(url, strategy).toPromise();
	}

	/**
	 * Setting up a new Sherpa.
	 * 1. rest-call to sherpa.json
	 * 2. for each function in json.functions create a http.post.toPromise()
	 */
	private setupSherpa(url: string, strategy: ErrorMessageStrategy): Observable<Sherpa> {
		return this.rest<SherpaJson>(url, true)
			.pipe(
				map((result: SherpaJson) => {
					const sherpaResult = new Sherpa(result);
					const baseUrl = _.trimEnd(sherpaResult.json.baseurl, '/') + '/';

					_.forEach(sherpaResult.json.functions, (fn: string) => {
						sherpaResult.api[fn] = <T>(...args: any[]) => this.sherpaPost(baseUrl + fn, {params: args}, strategy);
					});

					return sherpaResult as Sherpa;
				})
			);
	}

	sherpaPost<T>(url: string, body: any, strategy: ErrorMessageStrategy): Promise<T> {
		return this.http
			.post<SherpaResponse>(url, JSON.stringify(body), this.httpOptions)
			.toPromise()
			.then((response: any) => {

				// call succeeded, but error in backend
				if (response.error !== null) {
					return Promise.reject(response.error);
				}

				return response.result as T;

			})
			.catch((error: any) => {
				let sherpaError: SherpaError;

				if (_.has(error, 'code') && _.has(error, 'message')) {
					sherpaError = new SherpaError(error.code, error.message);
				} else if (error instanceof Error) {
					sherpaError = new SherpaError('sherpaClientError', error.message);
				} else if (error.status === 404) {
					sherpaError = new SherpaError('sherpaBadFunction', 'function does not exist');
				} else {
					sherpaError = new SherpaError('sherpaHttpError', error.message);
				}

				// show the sherpaError conform the strategy
				strategy.show(sherpaError);

				return Promise.reject(sherpaError);
			});
	}

	rest<T>(url: string, once: boolean = false): Observable<T> {
		const ob = this.http
			.get<T>(url)
			.pipe(
				retry(3), // aka 3 attempts
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);

		if (once) {
			return ob.pipe(
				shareReplay() // call it just once
			);
		}

		return ob;
	}

	getImage(imageUrl: string): Observable<{ filename: any; data: any }> {
		return this.http
			.get(imageUrl, {
				responseType: 'blob'
			})
			.pipe(
				map(res => {
					return {
						filename: 'test',
						data: res
					};
				})
			);
	}
}

/* Strategy pattern; How to show sherpa-errors. Some examples below */

export interface ErrorMessageStrategy {
	show(error: SherpaError): void;
}

export class ConsoleErrorMessage implements ErrorMessageStrategy {
	show(error: SherpaError) {
		console.error(error.message);
	}
}

export class AlertErrorMessage implements ErrorMessageStrategy {
	show(error: SherpaError) {
		window.alert(error.message);
	}
}

export class CustomErrorMessage implements ErrorMessageStrategy {
	show(error: SherpaError) {
		if (_.isEqual(error.code, 'sherpaClientError')) {
			// fancy error
		} else {
			// less fancy error
		}
	}
}
