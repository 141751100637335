import {SherpaJson} from './SherpaJson';

export interface SherpaApi {
	[key: string]: <T>(...args: any[]) => Promise<T>;
}

export class Sherpa {
	api: SherpaApi = {};

	constructor(public json: SherpaJson) {
	}
}
