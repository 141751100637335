import {Injectable}                       from '@angular/core';
import {Storage}                          from '@ionic/storage-angular';
import {sherpa}                           from '../app.module';
import {environment}                      from '../../environments/environment';
import _                                  from 'lodash';
import {Route}                            from '../classes/routes/Route';
import {RoutesWrapper}                    from '../classes/routes/RoutesWrapper';
import {Icon}                             from '../classes/routes/Icon';
import {ActiveRoute}                      from '../classes/core/ActiveRoute';
import {NetworkStatus, NetworkService} from './network.service';
import {ToastController}              from '@ionic/angular';
import {UtilService}                  from './util.service';
import {Image}                        from '../classes/routes/Image';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {RoutePayment}                 from '../classes/routes/RoutePayment';
import {RoutePaymentInfo}             from '../classes/routes/RoutePaymentInfo';
import {Drivers}                      from '@ionic/storage';
import {RouteDownload}                from '../classes/routes/RouteDownload';
import {ApiSource}                    from '../classes/core/ApiSource';
import {PublishedRoutesResult}        from '../classes/routes/PublishedRoutesResult';
import {Router}                       from '@angular/router';
import {TranslateService}             from '@ngx-translate/core';

const DEFAULT_EXPIRY_TIME = 24 * 60; // 24*60 = 24 hours

export const ROUTES_KEY = 'routes';
export const FILTER_KEY = 'filter';
export const SORT_KEY = 'sort';
export const LAST_VISITED_KEY = 'last-visited';
export const FAVORITES_KEY = 'favorites';
export const DOWNLOADS_KEY = 'downloads';
export const REVIEWS_KEY = 'reviews';
export const IMAGES_KEY = 'images';
export const TEST_MODE_KEY = 'test-mode';
export const ROUTE_FIELDS = 'route-fields';
export const ROUTE_SCORES = 'route-scores';
export const CUSTOMERS = 'customers';
export const PAYMENTS_KEY = 'route-payments';
export const ROUTE_CODES_KEY = 'route-codes';
export const NEWSLETTER_CONSENT_KEY = 'newsletter-consent';
export const USER_CREATED_ROUTES = 'user-created-routes';
export const ACCEPTED_DISCLOSURE_MODAL = 'accepted-disclosure-modal';
export const LANGUAGE = 'language';
export const CARD_VIEW_KEY = 'card-view';


@Injectable({
	providedIn: 'root'
})
export class DataService {

	db: Storage; // main storage
	imageDb: Storage;
	routeDetailDb: Storage;
	userCreatedRouteDb: Storage;

	testMode: boolean;
	testModeSubject: Subject<boolean>;

	initRoutes = true; // start app
	resetRouteItemSubject = new Subject(); // can be used to trigger init() for route item component

	constructor(private storage: Storage, private networkService: NetworkService, private toast: ToastController, private util: UtilService,
				private router: Router, private translate: TranslateService) {
	}


	/* CORE */

	async createStorages() {
		// used for caching images
		this.imageDb = await new Storage({
			name: '__imageCache',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}).create();

		// used for route-detail (contains routes with geometries and supports multiple languages)
		this.routeDetailDb = await new Storage({
			name: '__routeDetail',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}).create();

		this.userCreatedRouteDb = await new Storage({
			name: '__userCreatedRoute',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}).create();

		return this.storage.create().then(storage => {
			this.db = storage;
			this.get(TEST_MODE_KEY).then(mode => {
				this.testMode = mode;
				this.testModeSubject = new Subject();
				this.subscribeForTestMode();
			});
			return storage;
		});
	}

	async clearStorages() {
		await this.db.clear();
		await this.imageDb.clear();
		await this.routeDetailDb.clear();
		await this.userCreatedRouteDb.clear();
	}

	set(key: string, value: any, storage = this.db): Promise<any> {
		return storage.set(key, value);
	}

	get(key: string, storage = this.db) {
		return storage.get(key);
	}

	async has(key: string, storage = this.db) {
		const keys = await storage.keys();
		return _.includes(keys, key);
	}

	getWithExpiry(key: string, storage = this.db): Promise<any> {
		return this.get(key, storage).then(value => {
			if (!value) {
				return null;
			}

			const now = new Date();

			if (now.getTime() > value.expiry) {
				return storage.remove(key).then(() => {
					return null;
				});
			}

			return value.value;
		});
	}

	setWithExpiryDefault(key, value, storage = this.db) {
		return this.setWithExpiry(key, value, DEFAULT_EXPIRY_TIME, storage);
	}

	setWithExpiry(key, value, minutesTillExpiration, storage = this.db): Promise<any> {
		const now = new Date();

		const item = {
			value,
			expiry: now.getTime() + (minutesTillExpiration * 60 * 1000),
		};

		return this.set(key, item, storage);
	}

	subscribeForTestMode() {
		this.testModeSubject.subscribe(value => {
			this.testMode = value;

			return this.set(TEST_MODE_KEY, value).then(() => {
				const message = this.testMode ? 'De app draait nu in testmodus' : 'Testmodus is nu uitgeschakeld';
				this.presentToast(message);
			});
		});
	}


	/* ROUTES */

	setRoutes(routes: Route[]): Promise<Route[]> {
		return this.get(ROUTES_KEY).then((wrapper: RoutesWrapper) => {
			if(wrapper == null) {
				return [];
			}

			wrapper.routes = routes;

			this.set(ROUTES_KEY, wrapper);
			return wrapper.routes;
		});
	}

	async getRoutes(force = false): Promise<Route[]> {
		// get routes from storage
		if (this.isOfflineMode() || !force) {
			return this.get(ROUTES_KEY).then(value => {
				if (value == null) {
					return [];
				}

				return value.routes;
			});
		}

		const storedRoutesWrapper = await this.get(ROUTES_KEY);
		let lastModified = storedRoutesWrapper == null ? 0 : storedRoutesWrapper?.last_modified || 0;

		// when first time (open app) always set to 0
		if (this.initRoutes) {
			lastModified = 0;
		}

		try {
			return this.fetchRoutes(lastModified)
				.then((result: PublishedRoutesResult) => {

					this.initRoutes = false;
					console.log('routes modified', result.is_modified);

					if (result.is_modified) {
						return this.handleGetRoutes(result).then(routes => {
							this.validateRoutesExtended(routes);
							return routes;
						});
					}

					if (storedRoutesWrapper == null) {
						return [];
					}

					// return cached
					return storedRoutesWrapper.routes;
				});
		}catch(err){
			this.presentToast(this.translate.instant('data.swipe'));
			return [];
		}
	}

	async handleGetRoutes(result: PublishedRoutesResult): Promise<Route[]> {
		const customers = _.get(result, ['publication', 'customers'], []);
		const allRoutes = [] as Route[];
		const allRouteFields = {}; // key-value
		const allCustomers = {}; // key-value

		const userLanguage = await this.getLanguage();

		_.forEach(customers, customer => {

			// is this property in use ???
			if (!customer.customer.routes_in_app) {
				// return; // continue loop
			}

			let routes: Route[] = customer.routes;
			_.remove(routes, ['disable_individual_route', true]);

			// dont store customers without routes
			if (_.isEmpty(routes)) {
				return; // continue loop
			}

			if (!_.isEmpty(environment.filterRoutes)) {
				routes = this.util.doRouteFiltering(routes, customer.options);
			}

			_.forEach(routes, (route: Route) => {
				if(_.includes(route.language_codes, userLanguage)){
					// Do something with the user language to get the correct title.
				}
				route._tiles_url = customer.customer.tiles_url;
				route._customerCode = customer.customer.code;
				route._customerColor = customer.customer.brand_color;
				route._source = result._source;
			});

			allRoutes.push(...routes);
			allRouteFields[customer.customer.code] = customer.options;
			allCustomers[customer.customer.code] = customer.customer;
		});

		await this.getUserCreatedRoutes().then(routes => {
			allRoutes.unshift(...routes);
		});

		this.setWithExpiryDefault(ROUTE_FIELDS, allRouteFields);
		this.setWithExpiryDefault(CUSTOMERS, allCustomers);

		const routesWrapper = {
			last_modified: allRoutes.length ? new Date().getTime() : 0,
			routes: allRoutes
		} as RoutesWrapper;


		return this.set(ROUTES_KEY, routesWrapper).then(value => {
			return value.routes;
		});
	}

	getRoute(routeId: number, withGeom = false, language = 'nl') {
		if(withGeom) {
			return this._getRouteExtended(routeId, language);
		}

		return this._getRoute(routeId);
	}

	private async _getRoute(routeId: number): Promise<Route|null> {
		const routes = await this.getRoutes();

		if (routes.length === 0) {
			await this.presentToast(this.translate.instant('data.noroutesloaded'));
			return null;
		}

		const route = _.find(routes, ['id', routeId]);
		if (!route) {
			await this.presentToast(this.translate.instant('data.noroutefound'));
			return null;
		}

		return route;
	}

	private async _getRouteExtended(routeId: number, language: string): Promise<Route|null> {
		const key = `${routeId}-${language}`;

		return this.get(key, this.routeDetailDb).then(async (result: Route) => {
			if(result == null) {
				const online = this.networkService.getCurrentNetworkStatus() === NetworkStatus.ONLINE;
				const route = await this._getRoute(routeId);

				if(!online || route == null) {
					return route;
				}

				// When Id is lower than 0 we dont have to fetch here.
				if (routeId < 0) {
					return route;
				}

				return this.fetchRoute(route.id, route._customerCode, language).then(routeWithGeoms => {
					routeWithGeoms._customerCode = route._customerCode;
					routeWithGeoms._tiles_url = route._tiles_url;

					// todo fix in routemaker-backend these are not set
					routeWithGeoms.download_url_normal_size = route.download_url_normal_size;
					routeWithGeoms.download_url_high_size = route.download_url_high_size;
					routeWithGeoms.rating = route.rating;
					routeWithGeoms.amount_of_reviews = route.amount_of_reviews;

					this.set(key, routeWithGeoms, this.routeDetailDb);
					return routeWithGeoms;
				});
			}

			return result;
		});
	}

	setRoute(route: Route): Promise<Route> {
		return this.get(ROUTES_KEY).then(async routesWrapper => {

			const key = _.findKey(routesWrapper.routes, ['id', route.id]);

			if (!key) {
				return null;
			}

			// update route in routes storage
			routesWrapper.routes[key] = route;
			await this.set(ROUTES_KEY, routesWrapper);

			return routesWrapper.routes[key];
		});
	}

	validateRoutesExtended(newRoutes: Route[]) {
		// remove from routeDetailDb when fetched route is newer than stored, (use download_url_mtime as publication date)
		this.routeDetailDb.forEach((routeOrDownload: Route | RouteDownload, key: string) => {
			let route = routeOrDownload as Route;
			if(_.has(routeOrDownload, 'route')) {
				route = (routeOrDownload as RouteDownload).route;
			}

			const found = _.find(newRoutes, ['id', route.id]);

			// remove non-existing route
			if(!found) {
				console.log('REMOVED');
				this.routeDetailDb.remove(key);
			}
			// remove, but skip downloaded, u can use an 'old' route, this can be updated manually
			else if(!_.endsWith(key, '-download') && found.download_url_mtime > route.download_url_mtime) {
				this.routeDetailDb.remove(key);
			}

			// new reviews dont affect download_url_mtime, just update this counter
			if(found && found.amount_of_reviews !== route.amount_of_reviews) {
				route.amount_of_reviews = found.amount_of_reviews;
				this.routeDetailDb.set(key, route);
			}

		});
	}


	/* FILTER - SORT */

	hasActiveFilters() {
		return this.get(FILTER_KEY).then(filterSettings => {
			if (filterSettings == null) {
				return false;
			}
			if (!_.has(filterSettings, 'activeFilters')) {
				return false;
			}
			return filterSettings.activeFilters?.length > 0;
		});
	}

	getRouteFields(customerCode: string) {
		return this.getWithExpiry(ROUTE_FIELDS).then(result => {
			if (result == null) {
				return {};
			}

			if (customerCode == null) {
				return result;
			}

			if (_.has(result, customerCode)) {
				return result[customerCode];
			}
		});
	}


	/* CUSTOMERS */

	getCustomer(customerCode: string) {
		return this.getWithExpiry(CUSTOMERS).then(result => {
			if (result == null) {
				return null;
			}

			if (_.has(result, customerCode)) {
				return result[customerCode];
			}

			return null;
		});
	}

	getCustomers() {
		return this.getWithExpiry(CUSTOMERS).then(result => {
			if (result == null) {
				return [];
			}

			return _.valuesIn(result);
		});
	}

	/* USERCREATED ROUTES */

	getUserCreatedRoutes(): Promise<Route[]> {
		return this.get(USER_CREATED_ROUTES, this.userCreatedRouteDb).then(value => {
			if (value == null) {
				return this.set(USER_CREATED_ROUTES, [], this.userCreatedRouteDb);
			}

			return value;
		});
	}

	private fetchUserCreatedRoute(routeId: number): Promise<any> {
		if (this.isOfflineMode()) {
			return Promise.resolve(null);
		}

		try {
			return sherpa.api.getUserCreatedRouteCustomerIndependent<any>(routeId);
		}catch(err){
			this.presentToast(this.translate.instant('data.noroutefound'));
			return Promise.resolve(null);
		}
	}


	addUserCreatedRouteToRoutes(routeId: number): void {
		if(Number.isInteger(routeId)) {

			this.getUserCreatedRoutes().then((routes: Route[]) => {

				this.fetchUserCreatedRoute(routeId).then(result => {

					const userCreatedRoute = result.route;

					// skip if route is already in list.
					if(_.some(routes, ['id', userCreatedRoute.id])){
						return;
					}

					routes.unshift(userCreatedRoute);
					this.set(USER_CREATED_ROUTES, routes, this.userCreatedRouteDb);

					this.setFavorite(userCreatedRoute.id);

					// Refresh the routes list.
					this.initRoutes = true;
					this.getRoutes(true).then((refreshedroutes: Route[]) => {
						if(this.router.url === '/app/favorieten'){
							this.router.navigate(['/app/favorieten'])
								.then(() => {
									window.location.reload();
								});
						}else {
							this.router.navigate(['/app/favorieten']);
						}
					});
				});
			});
		}
	}

	removeUserCreatedRoute(routeId: number): void {
		if(Number.isInteger(routeId)) {

			this.getUserCreatedRoutes().then((routes: Route[]) => {

					// skip if route is not in list.
					if(!_.some(routes, ['id', routeId])){
						return;
					}

					_.remove(routes, ['id', routeId]);
					this.set(USER_CREATED_ROUTES, routes, this.userCreatedRouteDb);

					this.removeFavorite(routeId);
			});
		}

		// Refresh the routes list.
		this.initRoutes = true;
		this.getRoutes(true);
	}

	/* FAVORITE ROUTES */

	isFavorite(routeId: number): Promise<boolean> {
		return this.getFavorites().then(favorites => {
			return favorites.includes(routeId);
		});
	}

	setFavorite(routeId: number): void {
		this.getFavorites().then(favorites => {
			favorites.push(routeId);
			this.set(FAVORITES_KEY, favorites);
		});
	}

	removeFavorite(routeId: number): void {

		// remove usercreatedroute
		if(routeId < 0){
			this.removeUserCreatedRoute(routeId);
		}

		this.getFavorites().then(favorites => {
			favorites = _.without(favorites, routeId);
			this.set(FAVORITES_KEY, favorites);
		});
	}

	getFavorites(): Promise<number[]> {
		return this.get(FAVORITES_KEY).then(value => {
			if (value == null) {
				return this.set(FAVORITES_KEY, []);
			}

			return value;
		});
	}


	/* DOWNLOAD ROUTES */

	isDownloadExpired(routeId: number, mTime: number) {
		return this.getDownload(routeId).then(downloadData => {
			if(downloadData == null) {
				return false;
			}

			return mTime > downloadData.route.download_url_mtime;
		});
	}

	setDownload(route: Route, result: any) {
		const language = 'nl'; // could be a param in future
		const downloadKey = `${route.id}-${language}-download`;

		const value = {
			tiles: result.tiles,
			images: result.images,
			route
		} as RouteDownload;

		this.set(downloadKey, value, this.routeDetailDb);
		return value;
	}

	removeDownload(routeId: number) {
		const language = 'nl'; // could be a param in future
		const downloadKey = `${routeId}-${language}-download`;
		this.routeDetailDb.remove(downloadKey);
	}

	getDownload(routeId: number) {
		const language = 'nl'; // could be a param in future
		const downloadKey = `${routeId}-${language}-download`;

		return this.get(downloadKey, this.routeDetailDb).then((downloadData: RouteDownload) => {
			if(downloadData == null) {
				return null;
			}

			return downloadData;
		});
	}

	getDownloadRouteIds() {
		return this.routeDetailDb.keys().then(keys => {
			const downloads = _.filter(keys, key => _.endsWith(key, '-download'));
			const ids = [];

			_.forEach(downloads, key => {
				const routeId = _.toInteger(_.head(_.split(key, '-')));
				ids.push(routeId);
			});

			return ids;
		});
	}

	hasDownloads(): Promise<boolean> {
		return this.routeDetailDb.keys().then(keys => {
			return _.some(keys, key => _.endsWith(key, '-download'));
		});
	}

	isDownloaded(routeId: number): Promise<boolean> {
		const downloadKey = `${routeId}-nl-download`;
		return this.get(downloadKey, this.routeDetailDb).then(r => {
			return r != null;
		});
	}


	/* PAYMENTS */

	createPayment(routeId: number, email: string) {
		const update = (updatedPayments) => {
			return this.set(PAYMENTS_KEY, updatedPayments).then(result => {
				return result[routeId];
			});
		};

		return this.get(PAYMENTS_KEY).then(async payments => {
			if (payments == null) {
				payments = {};
			}

			const paymentExists = _.has(payments, [routeId, 'route_payment_id']);
			let promoCode: string = null;

			if (paymentExists) {
				promoCode = payments[routeId].promo_code;

				// todo check status, als verlopen dan verwijder en ga door
			}

			return sherpa.api.createPaymentForRoute(routeId, promoCode, email).then((result: RoutePayment) => {
				payments[routeId] = {
					promo_code: promoCode,
					route_payment_id: result.route_payment_id,
					status: result.status
				};
				return update(payments).then(x => {
					return result;
				});
			});

		});
	}

	getPaymentStatus(routeId: number, promoCode: string = null) {
		const update = (updatedPayments) => {
			return this.set(PAYMENTS_KEY, updatedPayments).then(result => {
				return result[routeId];
			});
		};

		return this.get(PAYMENTS_KEY).then(payments => {
			if (payments == null) {
				payments = {};
			}

			console.log('has', _.has(payments, [routeId, 'route_payment_id']));

			if (_.has(payments, [routeId, 'route_payment_id'])) {
				return sherpa.api.checkRoutePayment(payments[routeId].route_payment_id, promoCode).then(payment => {
					payments[routeId] = payment;
					return update(payments);
				});
			}

			return sherpa.api.getPaymentSetting(routeId).then(result => {
				if (result == null) {
					return null;
				}
				payments[routeId] = result;
				return update(payments);
			});

		});
	}

	getRoutePaymentInfo(routeId: number, promoCode: string = null) {

		const update = (updatedPayments) => {
			return this.set(PAYMENTS_KEY, updatedPayments).then(result => {
				return result[routeId];
			});
		};

		return this.get(PAYMENTS_KEY).then(payments => {
			if (payments == null) {
				payments = {};
			}

			const exists = _.has(payments, [routeId]);

			// 1. update promoCode
			if (!exists) {
				payments[routeId] = {promo_code: promoCode};
			} else if (promoCode != null) {
				payments[routeId].promo_code = promoCode;
			}

			const promo = payments[routeId].promo_code || null;
			const paymentId = payments[routeId].route_payment_id || null;

			return sherpa.api.getRoutePaymentInfo(routeId, promo, paymentId).then((result: RoutePaymentInfo) => {
				if (result == null) {
					this.removePaymentInfo(routeId);
					return null;
				}

				payments[routeId] = {
					enabled: true,
					promo_code: result.promo_code || null,
					route_payment_id: result.payment !== null ? result.payment.route_payment_id : null,
					status: result.payment !== null ? result.payment.status : null
				};

				return update(payments).then(x => {
					return result;
				});
			});

		});
	}

	removePaymentInfo(routeId: number) {
		return this.db.get(PAYMENTS_KEY).then(result => {
			if(result == null) {
				return;
			}

			delete result[routeId];
			return this.set(PAYMENTS_KEY, result);
		});
	}

	resetPaymentInfo(routeId: number) {
		return this.db.get(PAYMENTS_KEY).then(result => {
			if(result == null) {
				return;
			}

			result[routeId].route_payment_id = null;
			return this.set(PAYMENTS_KEY, result);
		});
	}


	/* REVIEWS */

	getReviews(routeId: number, force = false) {
		if (!environment.reviewsEnabled || this.isOfflineMode()) {
			return of([]).toPromise();
		}

		return this.getWithExpiry(REVIEWS_KEY).then(reviews => {
			if (reviews == null) {
				reviews = {};
			} else if (_.has(reviews, routeId) && !force) {
				return reviews[routeId];
			}

			return sherpa.api.getReviews(routeId).then(result => {
				reviews[routeId] = result;
				this.setWithExpiryDefault(REVIEWS_KEY, reviews);

				return reviews[routeId];
			});
		});
	}


	/* IMAGES */

	getLogo(): Promise<string> {
		const key = '/logo.png';
		return this.getWithExpiry(key, this.imageDb).then(result => {
			if (result == null) {
				const baseUrl = _.trimEnd(environment.routemakerContentUrl, '/');

				// try fetch
				return this.util.getBase64ImageFromUrl(`${baseUrl}${key}`).then(imageData => {
					this.setWithExpiryDefault(key, imageData, this.imageDb);
					return imageData;
				});
			}

			return result;
		});
	}

	getImage(routeImage: Image, width = 360, height = 120): Promise<string> {
		const fallbackImage = 'assets/images/image-not-found.png';

		if(routeImage == null) {
			return Promise.resolve(fallbackImage);
		}

		const key = this.util.getImageUrlCutout(routeImage, width, height);

		return this.getWithExpiry(key, this.imageDb)
			.then(result => {
				if (result == null) {
					const baseUrl = _.trimEnd(environment.routemakerContentUrl, '/');

					// try fetch
					return this.util.getBase64ImageFromUrl(`${baseUrl}${key}`).then(imageData => {
						this.setWithExpiryDefault(key, imageData, this.imageDb);
						return imageData;
					});
				}

				return result;
			})
			.catch(e => {
				// console.log(routeImage.id, e.message);
				return fallbackImage;
			});
	}

	getFavicon(customerCode: string) {
		return this.getCustomer(customerCode).then(customer => {
			if (customer == null) {
				return null;
			}

			return `data:image/jpg;base64,${customer.favicon}`;
		});
	}

	getReviewsEnabled(customerCode: string) {
		return this.getCustomer(customerCode).then(customer => {
			if (customer == null) {
				return null;
			}

			return customer.reviews_enabled;
		});
	}

	getIcons(): Promise<Icon[]> {
		return this.getWithExpiry('icons').then((icons: Icon[]) => {
			const online = this.networkService.getCurrentNetworkStatus() === NetworkStatus.ONLINE;

			if (icons === null && online) {
				return sherpa.api.getIcons(environment.customerCode).then(async (iconsResult: Icon[]) => {
					this.setWithExpiryDefault('icons', iconsResult);
					return iconsResult;
				});
			}

			return icons;
		});
	}


	/* FETCH DATA */

	private fetchRoutes(lastModified: number): Promise<PublishedRoutesResult> {

		if (this.isOfflineMode()) {
			return Promise.resolve({is_modified: false, publication: null, _source: ApiSource.UNKNOWN});
		}

		let customerCode = environment.customerCode;

		if (environment.fetchAllCustomers != null && environment.fetchAllCustomers === true) {
			customerCode = null;
		}

		if (this.testMode) {
			return sherpa.api.getPublishedRoutesShortTest<PublishedRoutesResult>(lastModified, customerCode)
				.then(result => {
					result._source = ApiSource.TEST;
					return result;
				});
		}

		return sherpa.api.getPublishedRoutesShort<PublishedRoutesResult>(lastModified, customerCode)
			.then(result => {
				result._source = ApiSource.PUBLISHED;
				return result;
			});
	}

	private fetchRoute(routeId: number, routeCustomerCode?: string, language = 'nl'): Promise<Route> {
		if (this.isOfflineMode()) {
			return Promise.resolve(null);
		}

		if (!routeCustomerCode) {
			routeCustomerCode = environment.customerCode;
		}

		if (this.testMode) {
			return sherpa.api.getPublishedRouteTest<any>(routeCustomerCode, routeId);
		}

		return sherpa.api.getPublishedRouteForLanguage<any>(routeCustomerCode, routeId, language);
	}

	changeRouteLanguage(routeId: number, language: string) {

		return this.getRoutes().then(routes => {
			if (routes.length === 0) {
				return null;
			}

			const route = _.find(routes, ['id', routeId]);
			if (!route) {
				return null;
			}

			if (this.networkService.getCurrentNetworkStatus() === NetworkStatus.ONLINE) {
				return this.fetchRoute(route.id, route._customerCode, language).then(routeWithGeoms => {
					route.colophon = routeWithGeoms.colophon;
					route.directions = routeWithGeoms.directions;
					route.introduction_text = routeWithGeoms.introduction_text;
					route.name = routeWithGeoms.name;
					route.pois = routeWithGeoms.pois;

					route.subname = routeWithGeoms.subname;

					return this.setRoute(route);
				});
			}

			return route;
		});

	}


	/* HELPERS */

	addToLastVisited(routeId: number) {
		this.get(LAST_VISITED_KEY).then((routeIds: number[]) => {
			if (routeIds == null) {
				routeIds = [];
			}

			if (!_.includes(routeIds, routeId)) {
				routeIds = [routeId, ...routeIds]; // add to first position in array
			}

			// limit list to max 5
			if (routeIds.length > 5) {
				routeIds = _.take(routeIds, 5);
			}

			this.set(LAST_VISITED_KEY, routeIds);
		});
	}

	setRouteScore(routeId: number, score: number) {
		this.get(ROUTE_SCORES).then(scores => {
			if (scores == null) {
				scores = {};
			}

			scores[routeId] = score;
			this.set(ROUTE_SCORES, scores);
		});
	}

	getRouteScore(routeId: number) {
		return this.get(ROUTE_SCORES).then(scores => {
			if (scores == null) {
				return null;
			}

			if (_.has(scores, routeId)) {
				return scores[routeId];
			}

			return null;
		});
	}

	setAcceptedDisclosure() {
		this.set(ACCEPTED_DISCLOSURE_MODAL, true);
	}

	getAcceptedDisclosure() {
		return this.get(ACCEPTED_DISCLOSURE_MODAL).then(result => {
			if (result == null) {
				return false;
			}

			return result;
		});
	}



	setLanguage(lang: string) {
		this.set(LANGUAGE, lang);
	}

	getLanguage() {
		return this.get(LANGUAGE).then(result => {
			if (result == null) {
				return null;
			}

			return result;
		});
	}



	async presentToast(message: string, position: ('bottom' | 'top' | 'middle') = 'bottom') {
		const toast = await this.toast.create({
			message,
			duration: 2000,
			position
		});
		await toast.present();
	}

	isOfflineMode(){
		const offline = this.networkService.getCurrentNetworkStatus() === NetworkStatus.OFFLINE;
		return offline;
	}
}
