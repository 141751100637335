import {ApplicationRef, Injectable, ViewContainerRef}                from '@angular/core';
import {IonRouterOutlet, ModalController, Platform, ToastController} from '@ionic/angular';
import {Capacitor}          from '@capacitor/core';
import {
	Channel,
	LocalNotifications,
	LocalNotificationSchema, ScheduleOptions
}                           from '@capacitor/local-notifications';
import {ActionPerformed}    from '@capacitor/local-notifications/dist/esm/definitions';
import {PoiDetailComponent} from '../pages/modals/poi-detail/poi-detail.component';
import {Route}              from '../classes/routes/Route';
import {Poi}                from '../classes/routes/Poi';
import _                          from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	channelId: string = null;
	notifier: Subject<{ poi: Poi, route: Route }> = new Subject();

	constructor(private platform: Platform, private modalController: ModalController, private toastController: ToastController) {
		this.requestPermission();
	}

	requestPermission() {
		LocalNotifications.requestPermissions().then(status => {
			console.log('[INITIALIZER] LocalNotifications', status);

			if(status.display === 'granted') {
				LocalNotifications.removeAllListeners();

				LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: ActionPerformed) => {
					this.handleAction(notificationAction.notification.extra);
				});
			}
		})
		.catch(e => {
			console.log('no permissions for LocalNotifications');
		});
	}

	async notify(poi: Poi, pois: Poi[], route: Route) {
		const isPoi = _.includes(['poi', 'simple-poi', 'label-poi'], poi.kind);
		const title = `U nadert een ${isPoi ? 'locatie' : poi.kind}.`;

		LocalNotifications.checkPermissions().then(status => {

			if(status.display === 'granted') {
				this.scheduleBasicPoiNotification(title, poi, route);

				// Besides the basisNotification also send a poiToast.
				this.poiToast(title, poi, pois, route);
			}
			else if(status.display === 'denied') {
				this.poiToast(title, poi, pois, route);
			}
			else {
				this.requestPermission();
			}
		})
		.catch(e => {
			console.log('fallback for LocalNotifications');
			this.poiToast(title, poi, pois, route);
		});
	}

	async poiToast(title: string, poi: Poi, pois: Poi[], route: Route) {
		const buttons = [];

		if(!_.endsWith(poi.kind, 'knooppunt')) {
			buttons.push({
				text: 'bekijk',
				role: 'ok',
				handler: () => {
					this.handleAction({
						route,
						poi,
						type: 'poi'
					});
				}
			});
		}

		buttons.push({
			text: 'sluit',
			role: 'cancel',
		});

		let msg = poi.name;
		msg += this.nextKnooppuntText(poi, pois);

		const toast = await this.toastController.create({
			header: title,
			message: msg,
			id: poi.id.toString(),
			position: 'top',
			mode: 'ios',
			color: 'light',
			buttons
		});

		await toast.present();
	}

	nextKnooppuntText(poi: Poi, pois: Poi[]){
		if(_.endsWith(poi.kind, 'knooppunt')) {

			let currentPoiFound = false;
			for (const currPoi of pois){
				if(currentPoiFound && currPoi != null && _.endsWith(currPoi.kind, 'knooppunt')){
					return ' het volgende knooppunt is: ' + currPoi.name;
				}
				if(currPoi != null && poi.id === currPoi.id) {
					currentPoiFound = true;
				}
			}


		}
		return '';
	}

	async scheduleBasicPoiNotification(title: string, poi: Poi, route: Route) {

		let routeStripped: any = {
			id: route.id
		};

		let poiStripped: any = {
			id: poi.id
		};

		const notification = {
			title,
			body: poi.name,
			id: poi.id,
			extra: {
				poi: poiStripped,
				route: routeStripped,
				type: 'poi'
			}
		} as LocalNotificationSchema;

		// Android only
		if(Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
			if(this.channelId == null) {
				const notificationChannel: Channel = {
					id: 'poi-notifications',
					name: 'POI notifications',
					description: 'POI notifications',
					importance: 5,
					visibility: 1,
					vibration: true
				};

				await LocalNotifications.createChannel(notificationChannel);
				this.channelId = 'poi-notifications';
			}

			// set the channelId
			notification.channelId = this.channelId;
		}

		console.log('schedule local notifications');

		const schema = {
			notifications: [notification]
		} as ScheduleOptions;

		await LocalNotifications.schedule(schema);
	}

	async handleAction(notificationData: any) {

		if (notificationData.type === 'poi') {
			this.notifier.next({
				poi: notificationData.poi,
				route: notificationData.route
			});
		}
	}
}
